import { render, staticRenderFns } from "./ToBeDeliveredOrder.vue?vue&type=template&id=e355bc22&scoped=true&"
import script from "./ToBeDeliveredOrder.vue?vue&type=script&lang=js&"
export * from "./ToBeDeliveredOrder.vue?vue&type=script&lang=js&"
import style0 from "./ToBeDeliveredOrder.vue?vue&type=style&index=0&id=e355bc22&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e355bc22",
  null
  
)

export default component.exports